// Variable overrides

// Font
$redHatFont: "Red Hat Display";
$openSans: "Open Sans";

// Colors
$white: #ffffff;
$black: #000000;
$background: #f8f8fa;

$primaryBlue: #133155; // Main blue color (buttons, icons, top of Sidebar, etc)

// Specific to Sidebar component
$sidebarBlue: #131A2C;
$sidebarHighlight: #2D4664;

$secondaryText: #BBBBBB; // Secondary text, separators

$input: #E9EDF3; // Background for input fields
$inputText: #757575;

$lightBlue: #4697E0; // Small things - Used as a circle icon (dashboard), underline on tabs
$orange: #E3843C; // Used on the news feed - dashboard

// Custom theme colors
$theme-colors: (
  "primaryBlue": $primaryBlue,
  "secondaryText": $secondaryText,
  "sidebarBlue": $sidebarBlue,
  "sidebarHighlight": $sidebarHighlight,
  "input": $input,
  "inputText": $inputText,
  "background": $background,
  "black": $black,
  "white": $white,
);

// Default variable overrides
$body-bg: $background !important;

// Input
$input-height-lg: 56px;

// Button
$btn-padding-y-lg: 1rem;
