.table.table-hover {
  tr {
    th {
      padding: 0px;
    }

    td {
      padding: 5px;
      vertical-align: middle;
      border-top: 1px solid #d8dbe0;
    }
  }
}

.heading {
  position: static;
  width: 166px;
  height: 32px;
  left: 0px;
  top: 0px;

  /* Heading/Small (24-32)/Bold */

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* neutral / 900 */

  color: #131313;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 8px;
}

.sub-heading {
  position: static;
  left: 0px;
  top: 0px;

  /* Heading/Small (24-32)/Bold */

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  /* identical to box height, or 133% */

  /* neutral / 900 */

  color: #131313;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 8px;
}

.csv-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  position: static;
  width: 135px;
  height: 40px;
  right: 184px;
  top: 0px;
  font-weight: bold !important;

  /* body */

  background: #ffffff;
  /* neutral / 900 */

  border: 1px solid #131313 !important;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}

.search-bar {
  position: relative;
  border: none;
  height: 40px;
  width: auto;
  left: 0%;
  right: 0%;
  top: 0px;
  // padding-bottom: 4px;
  padding-left: 20px;
  margin-left: auto;

  /* neutral / 100 */

  background: #eeeeee;
  border-radius: 32px;
}

.global-add-buton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  color: #fff;
  position: static;
  min-width: 123px;
  width: max-content;
  height: 40px;
  right: 0px;
  top: 0px;

  /* primary/500 */

  background: #2b4f7c;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 12px;

  &:hover {
    color: #fff;
  }
}

.content {
  margin-left: 256px;
}

.footer {
  z-index: 999;
}

button:disabled {
  cursor: not-allowed;
}

.add-all-recipients {
  margin-left: 0.3em;
}