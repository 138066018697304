// Here you can add other styles
@import "../../node_modules/@coreui/coreui/scss/coreui";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

//variables Start
$mainFont: "Open Sans";
$primaryColor: #2b4f7c;
$secondaryColor: lightblue;
$unSelectedMenueColor: #646464;
$headerUnSelectedColor: #131313;
$templateBordersColor: #eeeeee;
//variables End

//
.c-header.c-header-fixed,
.c-sidebar.c-sidebar-fixed {
    z-index: 999 !important;
}

@mixin ant-design-modal-button-common-styles {
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 30px;
    font: normal normal 600 18px 'Open Sans', sans-serif;
    border: 1px solid #133155;
    height: 44px;

    :hover {
        color: #4697E0;

        * {
            color: #4697E0;
        }
    }
}

html {
    -webkit-font-smoothing: antialiased;

    .ant-modal {
        .ant-modal-content {
            border-radius: 30px !important;
        }

        .ant-modal-confirm-content {
            margin-left: 0 !important;
            text-align: left !important;

            color: black;
            font-family: $openSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-align: center;

            margin-bottom: 0;
        }

        .ant-btn-default {
            @include ant-design-modal-button-common-styles;
            background-color: white;
            color: #133155;
        }

        .ant-btn-primary {
            @include ant-design-modal-button-common-styles;
            background-color: #133155;
            color: white;
        }

        .ant-modal-body {
            width: 550px;
            border-radius: 30px !important;
            background: #F8F8FA;
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 48px 46px !important;
        }

        .ant-modal-confirm-title {
            color: $black;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 56px;
        }

        .anticon-exclamation-circle {
            display: none;
        }

    }

    .input {
        border-radius: 10px !important;
        background-color: $input !important;
        color: $inputText !important;
        border: none;
        padding-left: 24px !important;
        box-shadow: none !important;
        border: none;

        &:disabled {
            cursor: not-allowed;
            filter: opacity(50%);
        }
    }

    .input[type=text]:focus {
        border: none !important;
        box-shadow: none;
    }

    .input::placeholder {
        color: $inputText;
        font-size: 16px !important;
        font-family: $openSans;
        font-weight: 600;
        line-height: 24px;
        word-wrap: break-word;
    }

    .authHeaderLabel {
        color: $black;
        font-size: 48px;
        font-family: $redHatFont;
        font-weight: 800;
        text-transform: capitalize;
        line-height: 64px;
        word-wrap: break-word;
    }

    .authSubHeaderLabel {
        font-size: 20px;
        font-family: $openSans;
        font-weight: 600;
        line-height: 32px;
        word-wrap: break-word;
        color: $inputText;
    }

    .authInputLabel {
        font-size: 14px;
        font-family: $openSans;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        color: $black;
    }

    .authParent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        height: 100%;
        width: 100%;
    }

    @include media-breakpoint-up(xxl) {
        .authParent {
            max-width: 580px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include media-breakpoint-down(lg) {
        .authParent {
            max-width: 480px;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .authSubText {
        font-weight: 600;
        font-size: 15px;
        color: $primaryBlue;

        &:hover {
            cursor: pointer;
            filter: opacity(80%);
        }
    }

    .input {
        border-radius: 10px !important;
        background-color: $input !important;
        color: $inputText !important;
        border: none;
        padding-left: 24px !important;
        box-shadow: none !important;
        border: none;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .input[type=text]:focus {
        border: none !important;
        box-shadow: none;
    }

    .input::placeholder {
        color: $inputText;
        font-weight: 600;
        font-size: 16px !important;
    }

    .input-label {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600 !important;
        color: $black;
    }

    .data-table {
        .table-responsive>div {
            height: 100%;
        }

        .selected-row {
            background-color: rgba(0, 0, 21, 0.075);
        }
    }

    .input-error {
        border: 1px solid red
    }

    .flex {
        display: flex;
    }

    .pagination {
        .page-item {
            a {
                color: $primaryColor !important;
            }

            &.active {
                a {
                    color: #fff !important;
                    background: $primaryColor !important;

                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

    .datacenter {
        background: white
    }

    .datacenter:hover {
        background: $secondaryColor !important;
    }

    .date-section {
        color: $primaryColor;
        font-size: 1.5rem;
    }

    .date-subsection {
        // color:$primaryColor;
        font-size: 1rem;
    }

    .comment {
        border: 1px solid #e4e7ea;
        overflow-y: scroll;
        height: 18rem;
    }

    body {
        background-color: $background;
    }

    .expired {
        color: red;
    }

    //dont change this this is css fro arrows for dropdown in sidebar
    .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
        content: url("https://firebasestorage.googleapis.com/v0/b/grata-b67fc.appspot.com/o/left-arrow.svg?alt=media&token=4485c7bd-762f-492b-8096-80877a7aa3db");
        background: none !important;
        height: auto !important;
    }

    .c-sidebar-nav-dropdown-toggle:hover::after {
        content: url("https://firebasestorage.googleapis.com/v0/b/grata-b67fc.appspot.com/o/left-arrow-white.svg?alt=media&token=965bc542-6802-4b6d-8ea3-51a92940052d");
    }

    .c-sidebar-nav-dropdown .c-show .c-sidebar-nav-dropdown-toggle::after {
        content: url("https://firebasestorage.googleapis.com/v0/b/grata-b67fc.appspot.com/o/left-arrow.svg?alt=media&token=4485c7bd-762f-492b-8096-80877a7aa3db");
    }

    .modal-dialog {
        font-family: $mainFont;

        .modal-content {
            border-radius: 30px;
            padding: 24px 24px;

            .modal-header {
                padding: 0;
                border-bottom: none;
                align-items: center;

                .modal-title {
                    color: $black;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 56px;
                }
            }

            .modal-body {
                padding: 0;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            .modal-footer {
                border-top: none;
            }
        }
    }

    .card {
        padding: 24px 32px;
        border-radius: 30px;
        background: $white;

        .card-body {
            padding: 0;
        }
    }

    #root {

        .c-wrapper {
            padding: 28px 48px;
        }

        .c-body {
            margin-top: 2rem;
            background-color: $background !important;
        }

        #preview {
            & img {
                object-fit: contain;
                max-width: 100%;
            }
        }

        .mr-1 {
            margin-right: 1em;
        }

        .popover-warning {
            position: absolute;
            background: #e0dbba;
            padding: 10px;
            border-radius: 8px;
            font-size: 12px;
        }

        .config-container {
            padding: 1em;
            border: 2px solid #d8dbe0;
            display: flex;
            flex-direction: column;
            row-gap: 1em;

            &>div {
                display: flex;
                flex-direction: row;
                column-gap: 2em;
                align-items: center;
            }

            .c-switch {
                min-width: 40px;
            }

            .config-row {

                &>div {
                    flex: 0 50%;
                    display: flex;
                    align-items: center;
                }

                .c-switch {
                    margin-left: auto;
                }
            }

            .last-config-row {
                margin-right: 2em;
            }

            .pin-and-location {
                display: flex;
                flex-basis: 100%;

                &>* {
                    flex-basis: 50%;
                }
            }

            .cursor-disabled {
                cursor: not-allowed;
            }
        }

        .table {
            .edit-container {
                &:hover {
                    background-color: inherit;
                }
            }

            .status-cell {
                cursor: default;
                z-index: 2;
            }

            .edit-width {
                width: 1em;
            }

            height: 100%;

            tbody {
                height: 100%;

                tr {
                    height: 100%;
                }
            }
        }

        //to show pagination on right side
        .card-body {
            nav {
                &[aria-label="pagination"] {
                    display: flex !important;
                    justify-content: flex-end;
                }
            }

            .user-tab-item {
                min-width: 6em;
                text-align: center;

                .user-tab-link {
                    color: #2b4f7c;
                }
            }
        }

        //hide scrol button on input number fields
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        //to make the backdrop blurry
        .modal {
            &.overflow-auto {
                &.fade {
                    &.show {
                        &.d-block {
                            backdrop-filter: blur(2px);
                        }
                    }
                }
            }
        }

        //changed to backDrop color
        .modal-backdrop {
            &.fade {
                &.show {
                    background: rgba(19, 19, 19, 0.32);
                }
            }
        }

        .error_for_modal {
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 100vh;
        }

        .serviceDetailEdit {
            font-family: $mainFont;
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            width: 435px;
            height: 100vh;
            z-index: 999;

            .serviceDetailHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 58px;
            }

            .warning-conainer {
                margin-top: 100%;
                color: #f96;
                border-radius: 8px;
                background-color: rgba(255, 204, 0, 0.2);
                padding: 12px;
            }

            p {
                margin: 0;
                margin-bottom: 5px;
            }

            .imgAndDetailDiv {
                border-bottom: 1px solid #e3e3e3;
                margin-bottom: 10px;
                padding-bottom: 15px;

                .imgDIv {
                    width: 50px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .rightCol {
                    padding-left: 0 !important;

                    p {
                        margin: 0;

                        &:nth-child(1) {
                            width: 500;
                            color: #131313;
                        }
                    }
                }
            }

            .statusDiv {
                border-bottom: 1px solid #e3e3e3;
                margin-bottom: 10px;
                padding-bottom: 15px;

                .leftCol {
                    border-right: 1px solid #e3e3e3;

                    .dropdown-btn {
                        width: 100px;
                        border-radius: 5px 0 0 5px;
                        height: 40px;
                    }

                    .dropdown-chevron {
                        border-radius: 0 5px 5px 0;
                        height: 40px;
                    }

                    .dropdown-menu {
                        left: 0 !important;
                    }

                    select {
                        border: none;
                        background: green;
                        color: #fff;
                        width: 125px;
                        border-radius: 15px;

                        option {
                            background: #fff;
                            color: #000;
                        }

                        &:focus {
                            box-shadow: none !important;
                        }
                    }

                    p {
                        width: 110px;
                        margin: 0 auto;
                        color: #fff;
                        border-radius: 8px;

                        &:nth-child(1) {
                            font-weight: 400;
                            color: #131313;
                            margin-left: 0;
                        }

                        &:nth-child(2) {
                            padding: 10px;
                            background: green;
                            text-align: center;
                        }
                    }
                }

                .rightCol {
                    text-align: right;

                    p {
                        margin: 0;

                        &:nth-child(1) {
                            font-weight: 400;
                            color: #131313;
                        }
                    }
                }
            }

            .subBody {

                input,
                select {
                    margin-bottom: 15px;
                    color: #131313;
                }

                .ant-picker {
                    width: 100%;
                    margin-bottom: 15px;
                    border-radius: 4px;

                    input {
                        height: 25px;
                        margin-bottom: 0;
                    }
                }
            }

            .modal-footer {
                .disable {
                    cursor: not-allowed;
                }
            }
        }

        .serviceDetailCard {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            width: 750px;
            height: 100vh;
            z-index: 999;

            .serviceDetailHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 58px;
            }

            .serviceDetailRow {
                background: #fff;

                .mainLeftCol {
                    border-right: 1px solid #e3e3e3;

                    .imgAndDetailDiv {
                        border-bottom: 1px solid #e3e3e3;
                        margin-bottom: 10px;
                        padding-bottom: 15px;

                        .imgDIv {
                            width: 50px;
                            margin: 0 auto;

                            img {
                                width: 100%;
                                object-fit: cover;
                            }
                        }

                        .rightCol {
                            padding-left: 0 !important;

                            p {
                                margin: 0;

                                &:nth-child(1) {
                                    width: 500;
                                    color: #131313;
                                }

                                &.createdDate {
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    .editTextDiv {
                        cursor: pointer;
                        text-align: center;
                        color: #2b4f7c;
                        border-bottom: 1px solid #e3e3e3;
                        margin-bottom: 20px;
                        font-weight: 600;

                        p {
                            display: inline-block;
                            margin-left: 5px;
                            margin-bottom: 12px;
                        }
                    }

                    .lowerSection {
                        overflow-y: scroll;
                        overflow-x: hidden;

                        @media (min-width: 756px) {
                            height: 450px;
                        }

                        @media (min-width: 1310px) {
                            height: 480px;
                        }

                        @media (min-width: 1610px) {
                            height: 725px;
                        }

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }

                        .row {
                            margin-bottom: 15px;

                            .leftCol {
                                padding-top: 10px;

                                svg {
                                    color: #131313;
                                }
                            }

                            .text-muted {
                                font-size: 12px;
                            }

                            p {
                                margin: 0;

                                &:nth-child(2) {
                                    color: #131313;
                                }
                            }
                        }
                    }
                }

                .mainRightCol {
                    .statusDiv {
                        border-bottom: 1px solid #e3e3e3;
                        padding-bottom: 21px;

                        .leftCol {

                            .dropdown-btn {
                                width: 100px;
                                border-radius: 5px 0 0 5px;
                                height: 40px;
                            }

                            .dropdown-chevron {
                                border-radius: 0 5px 5px 0;
                                height: 40px;
                            }

                            .dropdown-menu {
                                left: 30px !important;
                            }

                            select {
                                border: none;
                                background: green;
                                color: #fff;
                                // border-radius: 15px;

                                option {
                                    background: #fff;
                                    color: #000;
                                }

                                &:focus {
                                    box-shadow: none !important;
                                }
                            }

                            p {
                                width: 110px;
                                margin: 0 auto;
                                color: #fff;
                                border-radius: 8px;

                                &:nth-child(1) {
                                    font-weight: 400;
                                    color: #131313;
                                    margin-left: 0;
                                }

                                &:nth-child(2) {
                                    padding: 10px;
                                    background: green;
                                    text-align: center;
                                }
                            }
                        }

                        .rightCol {
                            text-align: left;

                            .date {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            p {
                                margin: 0;

                                &:nth-child(1) {
                                    &.status {
                                        color: #131313;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }

                    .assignScheduleDIv {
                        margin-bottom: 15px;

                        .row {
                            border-bottom: 1px solid #e3e3e3;

                            .col-sm-5 {
                                background: #f6f6f6;
                                padding: 11px;

                                &:nth-child(1) {
                                    border-right: 1px solid #e3e3e3;
                                }

                                &:nth-child(2) {
                                    border-right: 1px solid #e3e3e3;
                                }
                            }

                            .operatorDropDownDiv {

                                .css-yk16xz-control,
                                .css-1pahdxg-control {
                                    border: none !important;
                                    box-shadow: none !important;

                                    &:focus {
                                        border: none !important;
                                    }
                                }
                            }

                            .operator {
                                border: none;
                                margin-top: 5px;

                                &:focus {
                                    box-shadow: none !important;
                                }
                            }

                            .ant-picker {
                                border: none;
                                margin-top: 8px;
                                box-shadow: none !important;
                            }
                        }
                    }

                    .ChatAreaMainDiv {
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        .messagesDiv {
                            scroll-behavior: smooth;
                            height: 360px;
                            margin-bottom: 5px;
                            padding-bottom: 5px;
                            border-bottom: 1px solid #e3e3e3;
                            overflow-x: hidden;
                            overflow-y: scroll;

                            @media (min-width: 756px) {
                                height: 360px;
                            }

                            @media (min-width: 1310px) {
                                height: 390px;
                            }

                            @media (min-width: 1610px) {
                                height: 630px;
                            }

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor !important;
                                }
                            }

                            .row {
                                margin-bottom: 15px;

                                &:nth-child(1) {
                                    margin-bottom: 10px;
                                }

                                &:nth-child(2) {
                                    margin-bottom: 25px;
                                }

                                &.nontechnician {
                                    border: 1px solid #d1d1d1;
                                    margin: 17px 0px;
                                    padding: 5px 0;
                                    border-radius: 8px;
                                }

                                &.Technician {
                                    border: 1px solid #d1d1d1;
                                    margin: 17px 0px;
                                    padding: 5px 0;
                                    background: rgba(43, 79, 124, 0.08);
                                    border-radius: 8px;
                                }

                                .profile-img {
                                    vertical-align: middle;
                                    height: 40px;
                                    border-radius: 50%;
                                    object-fit: cover;
                                }

                                p {
                                    margin: 0;

                                    &.messageSender {
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 20px;
                                    }

                                    &.userType {
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 18px;
                                    }

                                    &.messageText {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #131313;
                                        width: 300px;
                                        direction: ltr;
                                    }
                                }

                                .timeCol {
                                    font-size: 12px;
                                    padding-left: 0;
                                    text-align: right;
                                }
                            }

                            .noConvImgDiv {
                                max-width: 336px;
                                margin: 0 auto;

                                .noChatImg {
                                    width: 100%;
                                }
                            }
                        }

                        .chatInputFieldDiv {
                            p {
                                margin: 0;
                            }

                            textarea {
                                resize: none;
                                margin-top: 5px;

                                &:focus {
                                    border: 1px solid #e3e3e3;
                                    box-shadow: none !important;
                                }

                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            .sendButtonCol {
                                display: flex;
                                align-items: center;
                                padding-left: 0;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                    color: #131313;

                                    &:hover {
                                        color: $primaryColor;
                                    }
                                }
                            }

                            .text-danger {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }

        .unitLockDetailsMainDiv,
        .editUnitFrm,
        .unitDetailMainDiv,
        .modalBookDetails,
        .modalBookingEdit,
        .buildingDetailMainDiv,
        .editBuildingFrm,
        .EditOrganizationFrm,
        .userDetailMainDiv,
        .EditUserFrm {
            .modal-dialog {
                position: fixed;
                right: 0;
                top: 0;
                width: 450px;
                height: 100%;
                margin: 0;

                .modal-content {
                    height: 100%;

                    .modal-body {
                        height: 100%;
                        padding: 0 15px;
                        overflow-y: scroll;
                        overflow-x: hidden;

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }

                        .readOnly {
                            background-color: rgb(213, 216, 217);
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        .addOrgModal {
            .modal-content {
                height: 100%;

                .modal-body {
                    padding-top: 0px;
                    height: 500px;

                    @media (min-height: 800px) {
                        height: 700px;
                    }

                    overflow-y: scroll;
                    overflow-x: hidden;

                    &::-webkit-scrollbar-track {
                        display: none;
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: none;
                    }

                    &:hover {
                        &::-webkit-scrollbar-track {
                            display: none;
                            // border: none;
                            // background-color: #f5f5f5;
                            // border-radius: 10px;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: $primaryColor;
                        }
                    }
                }
            }
        }

        .organizationDetailMainDiv {
            .modal-dialog {
                position: fixed;
                //z-index: 1000;
                right: 0;
                top: 0;
                width: 450px;
                height: 100%;
                margin: 0;

                .modal-content {
                    height: 100%;

                    .editButtinDiv {
                        margin: 0;

                        .editIconDiv {
                            margin: 0 15px;
                            padding: 15px 0;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 600;
                            border-bottom: 1px solid #e3e3e3;
                        }
                    }

                    .modal-body {
                        height: 100%;
                        padding: 0 15px;
                        overflow-y: scroll;
                        overflow-x: hidden;

                        .avatar-div {
                            width: 100px;
                            margin: auto;
                            margin-top: 15px;
                            margin-bottom: 15px;

                            img {
                                vertical-align: middle;
                                height: 100px;
                                object-fit: cover;
                            }
                        }

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }

                        .addBuilding {
                            cursor: pointer;
                        }

                        .DetailRow {
                            padding-bottom: 15px;
                            margin-bottom: 15px;
                            border-bottom: 1px solid #e3e3e3;

                            .IconCol {
                                display: flex;
                                align-items: center;
                            }

                            .key {
                                font-size: 14px;
                            }

                            .value {
                                color: #131313;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        //for modal for edit book amenities
        .modalBookingEdit,
        .modalBookDetails {
            font-family: $mainFont;

            .modal-dialog {
                max-width: 400px;
                width: 400px;
                margin: 0;
                position: fixed;
                top: 0;
                right: 0;
                height: 100%;

                .modal-content {
                    min-height: 100%;
                    height: auto;
                    max-height: fit-content;

                    .editTextDiv {
                        cursor: pointer;
                        text-align: center;
                        color: #2b4f7c;
                        border-bottom: 1px solid #e3e3e3;
                        margin: 0;
                        margin-bottom: 20px;
                        font-weight: 600;
                        padding: 15px 0;

                        p {
                            display: inline-block;
                            margin: 0;
                            margin-left: 5px;
                        }
                    }

                    .modal-body {
                        padding: 15px;

                        .imgAndDetailDiv {
                            border-bottom: 1px solid #e3e3e3;
                            margin-bottom: 10px;
                            padding-bottom: 15px;

                            .imgDIv {
                                width: 50px;
                                margin: 0 auto;

                                img {
                                    width: 100%;
                                    height: 48px;
                                    object-fit: cover;
                                }
                            }

                            .rightCol {
                                padding-left: 0 !important;

                                p {
                                    margin: 0;

                                    &:nth-child(1) {
                                        width: 500;
                                        color: #131313;
                                    }
                                }
                            }
                        }

                        .statusDiv {
                            border-bottom: 1px solid #e3e3e3;
                            margin-bottom: 10px;
                            padding-bottom: 15px;

                            .leftCol {
                                display: flex;
                                align-items: center;
                                border-right: 1px solid #e3e3e3;

                                p.statusPara {
                                    margin: 0;
                                    width: 115px;

                                    span {
                                        font-size: 12px !important;
                                        padding: 20px !important;
                                        display: flex !important;
                                        height: 40px !important;
                                        width: 100% !important;
                                        margin: 0 auto !important;
                                        align-items: center !important;
                                        justify-content: center !important;
                                    }
                                }
                            }

                            .rightCol {
                                text-align: right;

                                p {
                                    margin: 0;

                                    &:nth-child(1) {
                                        font-weight: 400;
                                        color: #131313;
                                    }
                                }
                            }
                        }

                        .select {
                            margin-bottom: 15px;
                        }

                        .bookingTenantDiv {
                            .row {
                                margin-bottom: 15px;

                                .leftCol {
                                    padding-top: 10px;

                                    svg {
                                        color: #131313;
                                    }
                                }

                                .text-muted {
                                    font-size: 12px;
                                }

                                p {
                                    margin: 0;

                                    &:nth-child(2) {
                                        color: #131313;
                                    }
                                }
                            }
                        }

                        .amenityDatePicker {
                            width: 100%;
                            padding: 5px 0;
                            height: 48px;
                            margin-bottom: 10px;

                            .react-date-picker__wrapper {
                                border-radius: 6px;
                                border: 1px solid #cccccc;
                                padding: 0 10px;
                            }
                        }

                        .amenityTimePicker {
                            width: 100%;

                            .rc-time-picker-input {
                                border-radius: 6px;
                                border: 1px solid #cccccc;
                                height: 35px;
                                margin-bottom: 15px;
                            }
                        }

                        .cancelBtn {
                            border: none !important;
                            box-shadow: none !important;
                            color: red;
                            font-weight: 600;
                            padding: 0;
                        }
                    }
                }
            }
        }

        .unitLockDetailsMainDiv,
        .unitDetailMainDiv {
            .modal-content {
                max-width: 450px;

                .editButtinDiv {
                    margin: 0;
                    margin-bottom: 25px;
                    border-bottom: 1px solid #e3e3e3;

                    .editIconDiv {
                        padding: 15px 0;
                        cursor: pointer;
                    }
                }

                .modal-body {
                    padding: 0 15px;

                    .DetailRow {
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #e3e3e3;

                        .IconCol {
                            display: flex;
                            align-items: center;
                        }

                        .key {
                            font-size: 14px;
                        }

                        .value {
                            color: #131313;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .dzu-dropzone {
            padding: 0 !important;
            overflow: hidden !important;
        }

        .addFormPlusButton {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .addAmenity {
            .modal-dialog {
                max-width: 450px;

                .modal-content {
                    .modal-body {
                        overflow-y: scroll;
                        height: 500px;
                        overflow-x: hidden;

                        @media (min-height: 800px) {
                            height: 700px;
                        }

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }
                    }
                }
            }
        }

        .createUserForm {
            .spin-icon {
                position: absolute !important;
                right: 10%;
                top: 5px;
            }

            .building-dropdown-position {
                position: relative;
            }

            .modal-content {
                max-width: 470px;

                .modal-body {
                    .roleTypeDiv {
                        padding: 0 15px;
                    }

                    .userSubBody {
                        height: 400px;

                        @media (min-height: 800px) {
                            height: 600px;
                        }

                        padding: 15px;
                        overflow-y: scroll;
                        overflow-x: hidden;

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }
                    }
                }
            }
        }

        .addOfferFrm {
            .modal-content {
                max-width: 400px;

                .modal-body {
                    height: 500px;

                    @media (min-height: 800px) {
                        height: 700px;
                    }

                    overflow-y: scroll;
                    overflow-x: hidden;

                    &::-webkit-scrollbar-track {
                        display: none;
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: none;
                    }

                    &:hover {
                        &::-webkit-scrollbar-track {
                            display: none;
                            // border: none;
                            // background-color: #f5f5f5;
                            // border-radius: 10px;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: $primaryColor;
                        }
                    }
                }
            }
        }

        .addBuildingFrm {
            .modal-content {
                max-width: 450px;

                .modal-body {
                    height: 500px;

                    @media (min-height: 800px) {
                        height: 700px;
                    }

                    overflow-x: hidden;
                    overflow-y: scroll;

                    &::-webkit-scrollbar-track {
                        display: none;
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: none;
                    }

                    &:hover {
                        &::-webkit-scrollbar-track {
                            display: none;
                            // border: none;
                            // background-color: #f5f5f5;
                            // border-radius: 10px;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: $primaryColor;
                        }
                    }
                }
            }
        }

        .userDetailMainDiv,
        .buildingDetailMainDiv,
        .offerDetail {
            .modal-content {
                .editButtinDiv {
                    margin: 0;

                    .editIconDiv {
                        margin: 0 15px;
                        padding: 15px 0;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 600;
                        border-bottom: 1px solid #e3e3e3;
                    }
                }

                .modal-body {
                    padding: 0 15px;

                    .avatar-div {
                        width: 100px;
                        margin: auto;
                        margin-top: 15px;
                        margin-bottom: 15px;

                        img {
                            vertical-align: middle;
                            height: 100px;
                            object-fit: cover;
                        }
                    }

                    .rounded-avatar-div {

                        img {
                            vertical-align: middle;
                            height: 100px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .DetailRow {
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #e3e3e3;

                        .IconCol {
                            display: flex;
                            align-items: center;
                        }

                        .key {
                            font-size: 14px;
                        }

                        .value {
                            color: #131313;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .offerDetail {
            .modal-content {
                .modal-body {

                    .logoDiv,
                    .mediaDiv {
                        width: 100px;
                        margin: 0 auto;
                    }

                    .carousel-preview {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .globalBuildingDD {
            font-family: $mainFont;

            .buildingDD {
                &__menu {
                    margin: 0.125rem auto;
                }

                &__option {
                    overflow-x: hidden;

                    &--is-focused {
                        background-color: $primaryColor;
                        color: #fff;
                    }

                    &--is-selected {
                        background-color: $primaryColor;
                        color: #fff;
                    }
                }

                &__value-container {
                    height: 38px;
                    padding: 0;

                    .css-1uccc91-singleValue {
                        padding-left: 7px;
                        padding-top: 4px;

                        .name {
                            font-weight: 500;
                        }
                    }
                }

                // style for react select scroll start
                &__menu-list::-webkit-scrollbar {
                    width: 7px;
                    height: 0px;
                }

                &__menu-list::-webkit-scrollbar-track {
                    background: none;
                }

                &__menu-list::-webkit-scrollbar-thumb {
                    background-color: $primaryColor;
                    border-radius: 8px;
                }

                // style for react select scroll end
            }

            .css-yk16xz-control,
            .css-1pahdxg-control {
                border: none !important;
                box-shadow: none !important;

                .css-1okebmr-indicatorSeparator {
                    display: none !important;
                }
            }

            .buildingInfo {
                color: $inputText;

                p {
                    margin: 0;
                    line-height: 20px;

                    &.allBuilding {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }

        .tableNav {
            h5 {
                color: #768192;
                margin: 0;
                line-height: 53px;

                &.selected {
                    color: $primaryColor;
                    border-bottom: 1px solid $primaryColor;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .addFormPlusButtonDiv {
            .addFormPlusButton {
                cursor: pointer;

                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        .LoginIndexDiv {
            height: 100vh;


            .logConnectionModal {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                row-gap: 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: #2b4f7c 1px solid;
                border-radius: 10px;
                padding: 5% 5%;
                background-color: rgba(125, 157, 197, 0.2);
                font-weight: 300;
                font-size: large;

                @media (min-width: 556px) {
                    padding: 10% 5%;
                    font-weight: 500;
                    font-size: large;
                }

                @media (min-width: 756px) {
                    padding: 5% 10%;
                    font-weight: 700;
                    font-size: larger;
                }
            }

            .leftImageDiv {
                position: fixed;
                left: 0;
                bottom: 0;

                img {
                    width: 100%;
                }
            }

            .rightImageDiv {
                @media (max-width: 1700px) {
                    width: 200px;
                }

                position: fixed;
                right: 0;
                bottom: 0;

                img {
                    width: 100%;
                }
            }

            .backToLogin {
                a {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: #2b4f7c;
                }

                .forgotPasswordMainDiv {
                    .resetLinkButton {
                        background-color: #e3e3e3 !important;
                        border: #e3e3e3 !important;
                        color: white;
                    }
                }
            }

            .loginLabel {
                font-size: 48px;
                line-height: 60px;
                font-weight: 700;
                color: $black;
            }

            .loginForm {
                font-family: $mainFont;

                .passwordDiv {
                    .ant-input-password {
                        padding: 0;

                        input {
                            padding: 8px;
                        }

                        .ant-input-suffix {
                            padding-right: 5px;
                        }
                    }
                }

                .link {
                    color: $primaryColor;
                    font-weight: 600;
                    margin-bottom: 15px;
                    cursor: pointer;
                }

                .forgotPass {
                    width: 122px;
                }

                .loginButton {
                    background: #e3e3e3;
                    border-color: #e3e3e3;
                }
            }

            .changePassBody {
                .ant-input-password {
                    border-radius: 8px;

                    input {
                        padding: 4px;
                    }
                }

                .disclaimer {
                    border-bottom: 1px solid #e3e3e3;

                    .col {
                        &:nth-child(2) {
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }
            }

            .forgotPasswordMainDiv {
                font-family: $mainFont;

                .forgot-image-row {
                    width: 150px;
                    margin: 0 auto 15px auto;

                    img {
                        width: 100%;
                    }
                }

                h4 {
                    margin-bottom: 0;
                    font-weight: 700;
                }

                .resetLinkbutton {
                    background-color: #e3e3e3 !important;
                    border: #e3e3e3;
                }
            }

            .OTPScreen {
                font-family: $mainFont;

                .link {
                    display: flex;
                    align-items: baseline;
                    color: $primaryColor;
                    text-decoration: none;
                    font-weight: 600;
                    margin-bottom: 15px;
                    cursor: pointer;
                }

                .OTPDiv {
                    .OTPInputField {
                        input {
                            width: 56px !important;
                            height: 56px !important;
                            border-radius: 5px;
                            margin-right: 10px;
                            border: 1px solid #d1d1d1;
                        }

                        &.error {
                            input {
                                border: 1px solid red;
                            }
                        }
                    }
                }

                button {
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .termPolicy {
                position: fixed;
                bottom: 20px;
                left: 0;
            }
        }

        //in header the global add button css
        .c-header {
            box-shadow: none !important;

            .c-header__nav-button {
                display: inline-flex;
                background-color: $input;
                border-radius: 10px;
                position: relative;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;

                &:hover {
                    background-color: lighten($inputText, 40%);
                }

                .c-header__nav-button-icon {
                    color: $primaryBlue;
                    font-size: 24px;
                }

                .c-header__nav-button-badge {
                    position: absolute;
                    border-radius: 9999px;
                    width: 12px;
                    height: 12px;
                    background-color: #EA4444;
                }
            }

            .c-header-nav__title {
                color: $black;
                font-weight: 700;
                font-size: 48px;
                margin-bottom: 0;
            }

            .c-header-nav {
                display: flex;
                justify-content: center;
                column-gap: 1rem;

                .dropdown-menu {
                    a {
                        &:focus {
                            background: $primaryColor !important;
                            color: #fff;
                        }
                    }
                }

                //style for sent emails modal
                .modalSentMsgInfo {
                    font-family: $mainFont;

                    .modal-body {
                        overflow-y: scroll;
                        height: 500px;

                        @media (min-height: 800px) {
                            height: 700px;
                        }

                        &::-webkit-scrollbar-track {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: none;
                        }

                        &:hover {
                            &::-webkit-scrollbar-track {
                                display: none;
                                // border: none;
                                // background-color: #f5f5f5;
                                // border-radius: 10px;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: $primaryColor;
                            }
                        }

                        button {
                            width: 100%;
                            border: none !important;
                            box-shadow: none !important;
                            text-align: left;
                            margin-bottom: 10px;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #131313;
                        }

                        .dataHolder {
                            border-bottom: 1px solid #e3e3e3;
                            margin-bottom: 15px;

                            .row {
                                padding: 0 15px;
                            }
                        }

                        .bodyDiv {
                            padding: 0 15px;
                        }
                    }
                }

                //style for sent emails modal
                .update-status-text {
                    color: gray;
                    font-size: small;
                }

                .confirmation-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .createServiceReqForm {
                    .modal {
                        .modal-dialog {
                            max-width: 400px;
                            width: 400px;

                            .modal-content {
                                height: 100%;

                                .modal-body {
                                    height: 500px;

                                    @media (min-height: 800px) {
                                        height: 700px;
                                    }

                                    overflow-x: hidden;
                                    overflow-y: scroll;

                                    &::-webkit-scrollbar-track {
                                        display: none;
                                    }

                                    &::-webkit-scrollbar {
                                        width: 10px;
                                        background-color: none;
                                    }

                                    .modal-dialog {
                                        max-width: 800px;
                                        width: 800px;
                                        margin: 0;
                                        position: fixed;
                                        top: 0;
                                        right: 0;
                                        height: 100%;
                                    }

                                    &:hover {
                                        &::-webkit-scrollbar-track {
                                            display: none;
                                            // border: none;
                                            // background-color: #f5f5f5;
                                            // border-radius: 10px;
                                        }

                                        &::-webkit-scrollbar {
                                            width: 10px;
                                            background-color: none;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 10px;
                                            background-color: $primaryColor;
                                        }
                                    }

                                    .descriptionRow,
                                    .subjectRow {
                                        .rightCol {
                                            text-align: right;

                                            &.limtExceed {
                                                color: red;
                                            }
                                        }
                                    }
                                }

                                .modal-footer {
                                    .disbale {
                                        cursor: not-allowed;
                                    }
                                }
                            }
                        }
                    }
                }

                .serviceDetail {
                    font-family: $mainFont;

                    .modal-dialog {
                        max-width: 800px;
                        min-width: 400px;
                        width: 100%;
                        margin: 0;
                        position: fixed;
                        top: 0;
                        right: 0;
                        height: 100%;

                        .modal-content {
                            height: 100%;

                            .modal-body {
                                padding-bottom: 0;
                            }
                        }
                    }
                }

                //modal for cancel booking for amenities
                .modalCancelBookAmenities {
                    font-family: $mainFont;

                    .modal {
                        z-index: 9994;

                        .modal-dialog {
                            max-width: 450px;

                            .modal-header,
                            .modal-footer {
                                border: none;
                            }

                            h3 {
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 28px;
                                margin-top: 15px;
                                color: #131313;
                            }

                            p {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                max-width: 400px;
                                color: #515151;
                            }
                        }
                    }

                    .modal-backdrop {
                        z-index: 9991;
                    }
                }

                //modal for cancel booking for amenities
                //modal for creating the book request for parking
                .modalBooking {
                    font-family: $mainFont;

                    .modal-dialog {
                        max-width: 400px;

                        .modal-body {
                            overflow-y: scroll;
                            height: 500px;

                            @media (min-height: 800px) {
                                height: 700px;
                            }

                            padding: 15px;

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            .select {
                                margin-bottom: 15px;
                            }

                            .amenityDatePicker {
                                width: 100%;
                                padding: 5px 0;
                                height: 48px;
                                margin-bottom: 10px;

                                .react-date-picker__wrapper {
                                    border-radius: 6px;
                                    border: 1px solid #cccccc;
                                    padding: 0 10px;
                                }
                            }

                            .amenityTimePicker {
                                width: 100%;

                                .rc-time-picker-input {
                                    border-radius: 6px;
                                    border: 1px solid #cccccc;
                                    height: 35px;
                                    margin-bottom: 15px;
                                }
                            }

                            input {
                                &.form-control {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }

                //modal for creating the book request for parking
                //for modal for edit book Parking
                .modalEditBookParking {
                    font-family: $mainFont;

                    .modal-dialog {
                        max-width: 400px;
                        width: 400px;
                        margin: 0;
                        position: fixed;
                        top: 0;
                        right: 0;
                        height: 100%;

                        .modal-content {
                            height: 100%;

                            .modal-body {
                                .subBodyDiv {
                                    @media (min-width: 756px) {
                                        height: 370px;
                                    }

                                    @media (min-width: 1310px) {
                                        height: 380px;
                                    }

                                    @media (min-width: 1610px) {
                                        height: 525px;
                                    }

                                    overflow-x: hidden;
                                    overflow-y: scroll;

                                    &::-webkit-scrollbar-track {
                                        display: none;
                                    }

                                    &::-webkit-scrollbar {
                                        width: 10px;
                                        background-color: none;
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        border-radius: 10px;
                                        background-color: none;
                                    }

                                    &:hover {
                                        &::-webkit-scrollbar-track {
                                            display: none;
                                            // border: none;
                                            // background-color: #f5f5f5;
                                            // border-radius: 10px;
                                        }

                                        &::-webkit-scrollbar {
                                            width: 10px;
                                            background-color: none;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 10px;
                                            background-color: $primaryColor;
                                        }
                                    }
                                }

                                padding: 15px;

                                .imgAndDetailDiv {
                                    border-bottom: 1px solid #e3e3e3;
                                    margin-bottom: 10px;
                                    padding-bottom: 15px;

                                    .imgDIv {
                                        width: 50px;
                                        margin: 0 auto;

                                        img {
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }

                                    .rightCol {
                                        padding-left: 0 !important;

                                        p {
                                            margin: 0;

                                            &:nth-child(1) {
                                                width: 500;
                                                color: #131313;
                                            }
                                        }
                                    }
                                }

                                .statusDiv {
                                    border-bottom: 1px solid #e3e3e3;
                                    margin-bottom: 10px;
                                    padding-bottom: 15px;

                                    .leftCol {
                                        display: flex;
                                        align-items: center;
                                        border-right: 1px solid #e3e3e3;

                                        p.status {
                                            margin: 0;
                                            width: 115px;

                                            span {
                                                font-size: 12px !important;
                                                padding: 20px !important;
                                                display: flex !important;
                                                height: 40px !important;
                                                width: 100% !important;
                                                margin: 0 auto !important;
                                                align-items: center !important;
                                                justify-content: center !important;
                                            }
                                        }
                                    }

                                    .rightCol {
                                        text-align: right;

                                        p {
                                            margin: 0;

                                            &:nth-child(1) {
                                                font-weight: 400;
                                                color: #131313;
                                            }
                                        }
                                    }
                                }

                                .editTextDiv {
                                    cursor: pointer;
                                    text-align: center;
                                    color: #2b4f7c;
                                    border-bottom: 1px solid #e3e3e3;
                                    margin-bottom: 20px;
                                    font-weight: 600;

                                    p {
                                        display: inline-block;
                                        margin-left: 5px;
                                    }
                                }

                                .select {
                                    margin-bottom: 15px;
                                }

                                .bookingTenantDiv {
                                    .row {
                                        margin-bottom: 15px;

                                        .leftCol {
                                            padding-top: 10px;

                                            svg {
                                                color: #131313;
                                            }
                                        }

                                        .text-muted {
                                            font-size: 12px;
                                        }

                                        p {
                                            margin: 0;

                                            &:nth-child(2) {
                                                color: #131313;
                                            }
                                        }
                                    }
                                }

                                .amenityDatePicker {
                                    width: 100%;
                                    padding: 5px 0;
                                    height: 48px;
                                    margin-bottom: 10px;

                                    .react-date-picker__wrapper {
                                        border-radius: 6px;
                                        border: 1px solid #cccccc;
                                        padding: 0 10px;
                                    }
                                }

                                .amenityTimePicker {
                                    width: 100%;

                                    .rc-time-picker-input {
                                        border-radius: 6px;
                                        border: 1px solid #cccccc;
                                        height: 35px;
                                        margin-bottom: 15px;
                                    }
                                }

                                .cancelBtn {
                                    border: none !important;
                                    box-shadow: none !important;
                                    color: red;
                                    font-weight: 600;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

                //for modal for edit book Parking
                //modal for cancel booking for Pakring
                .modalCancelBookParking {
                    font-family: $mainFont;

                    .modal {
                        z-index: 9994;

                        .modal-dialog {
                            max-width: 450px;

                            .modal-header,
                            .modal-footer {
                                border: none;
                            }

                            h3 {
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 28px;
                                margin-top: 15px;
                                color: #131313;
                            }

                            p {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                max-width: 400px;
                                color: #515151;
                            }
                        }
                    }

                    .modal-backdrop {
                        z-index: 9991;
                    }
                }

                //modal for cancel booking for Pakring

                .msgNotificationDropDOwn {
                    font-family: $mainFont;

                    .dropdown-menu {
                        transform: translate(0, 45px) !important;
                        min-width: 441px;

                        .dropdown-item {
                            cursor: default;

                            &:active {
                                background: none !important;
                                color: #455164;
                            }

                            &:focus {
                                background: none !important;
                                color: #455164;
                            }

                            strong {
                                &.viewAllMsgs {
                                    cursor: pointer;
                                }
                            }
                        }

                        .headerRow {
                            .rightCol {
                                a {
                                    width: 150px !important;
                                    min-width: 150px !important;
                                    float: right;

                                    &:hover {
                                        background: none;
                                    }

                                    p {
                                        margin: 0;
                                        color: $primaryColor;
                                        font-weight: 600;
                                        font-size: 15px;
                                    }
                                }
                            }
                        }

                        .notificationDiv {
                            max-height: 330px;
                            overflow-x: hidden;
                            overflow-y: scroll;

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            .dropdown-item {
                                min-width: 441px;

                                .message {
                                    width: 440px;
                                }
                            }

                            .unread {
                                background: rgba(43, 79, 124, 0.08) !important;
                            }
                        }

                        .empty-notification-container {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            .empty-notification-text {
                                width: auto;
                                margin: 1em 0 1em 0;
                            }
                        }
                    }
                }

                .HelpModal {
                    font-family: $mainFont;

                    .modal-body {
                        .customeDiv {
                            &:nth-child(1) {
                                border-bottom: 1px solid #e3e3e3;
                            }

                            h6 {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 24px;
                            }

                            p {
                                margin: 0;

                                &.value {
                                    color: $primaryColor;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    font-size: 16px;

                                    a {
                                        font-size: 16px;
                                        color: $primaryColor;
                                        text-decoration: none;
                                    }
                                }
                            }

                            svg {
                                width: 25px;
                                height: 25px;
                                color: #131313;
                            }
                        }
                    }
                }

                .topBarProfileDropDown {
                    font-family: $mainFont;

                    .imgDiv {
                        width: 40px;
                        margin: 0 auto;

                        .profile-img {
                            width: 100%;
                            vertical-align: middle;
                            height: 40px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .dropdown-menu {
                        position: absolute;
                        inset: 2px auto auto 0px !important;
                        margin: 0px;
                        transform: translate(-183.8px, 52px) !important;
                        width: 260px !important;

                        a {
                            &.active {
                                background: none !important;
                            }

                            &:hover {
                                background: #e3e3e3 !important;
                            }
                        }

                        .dropdown-item {

                            &:active,
                            &:focus {
                                background: #e3e3e3 !important;
                            }

                            .row {
                                width: 65px;
                                flex-wrap: nowrap;
                            }

                            p {
                                color: #131313;
                                font-weight: 400;
                            }

                            &.first {
                                margin-top: 5px;
                            }

                            &.last {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .topBarPlusDD {
                    font-family: $mainFont;

                    // .dropdown-menu {
                    //     width: 235px;
                    //     transform: translate(-203.8px, 44px) !important;

                    //     .dropdown-item {
                    //         .row {
                    //             width: 65px;
                    //         }

                    //         &:active,
                    //         &:focus {
                    //             background: #e3e3e3 !important;
                    //         }

                    //         p {
                    //             margin: 0;
                    //             color: #131313;
                    //             font-weight: 400;
                    //         }

                    //         &.first {
                    //             margin-top: 5px;
                    //         }

                    //         &.last {
                    //             margin-bottom: 5px;
                    //         }
                    //     }
                    // }
                }

                .ModalEditProfile {
                    font-family: $mainFont;

                    .modal-content {
                        max-width: 456px;

                        .modal-body {
                            h3 {
                                font-size: 16px;
                                font-weight: 600;
                                margin-bottom: 15px;
                            }

                            overflow-y: scroll;
                            height: 500px;

                            @media (min-height: 800px) {
                                height: 700px;
                            }

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            // .uploadImageDiv {
                            //   margin-bottom: 25px;
                            //   padding-bottom: 25px;
                            //   border-bottom: 1px solid #e3e3e3;
                            //   .leftCol {
                            //     .profileImg {
                            //       vertical-align: middle;
                            //       width: 67px;
                            //       height: 67px;
                            //       border-radius: 50%;
                            //     }
                            //   }
                            //   .rightCol {
                            //     display: flex;
                            //     align-self: center;
                            //     // .imgUploaderDiv {
                            //     //   width: 165px;
                            //     //   height: 90px;
                            //     //   .fileUploader {
                            //     //     border: none !important;
                            //     //     .fileContainer {
                            //     //       box-shadow: none !important;
                            //     //       border: none !important;
                            //     //       padding: 0;
                            //     //       .uploadPicturesWrapper > div {
                            //     //         position: absolute !important;
                            //     //         width: 276px !important;
                            //     //         top: -25px;
                            //     //         left: -202px;
                            //     //       }
                            //     //       .uploadPictureContainer {
                            //     //         padding: 0px !important;
                            //     //         border-radius: 34px !important;
                            //     //         img {
                            //     //           vertical-align: middle;
                            //     //           width: 68px;
                            //     //           height: 68px;
                            //     //           border-radius: 50%;
                            //     //         }
                            //     //       }
                            //     //     }
                            //     //   }
                            //     // }
                            //     input {
                            //       position: absolute;
                            //       font-size: 50px;
                            //       opacity: 0;
                            //       right: 0;
                            //       top: 0;
                            //     }
                            //   }
                            // }

                            .passwordLabel {
                                font-size: 16px;
                                margin: 0;
                                margin-bottom: 5px;
                                font-weight: 600;
                                color: black !important;

                                &.error {
                                    color: red !important;
                                }
                            }

                            // P {
                            //   font-size: 16px;
                            //   margin: 0;
                            //   margin-bottom: 5px;
                            //   font-weight: 600;
                            //   color: black !important;
                            //   &.error {
                            //     color: red !important;
                            //   }
                            // }
                            input {
                                margin-bottom: 15px;
                                color: #131313;
                            }

                            // button {
                            //   background: none !important;
                            //   box-shadow: none !important;
                            //   border-radius: 8px !important;
                            //   border: 1px solid #e3e3e3 !important;
                            //   color: #131313;
                            //   font-weight: 600 !important;
                            //   &.disabled {
                            //     cursor: not-allowed;
                            //   }
                            // }
                            .readOnly {
                                background-color: #d5d8d9;
                                cursor: not-allowed;
                            }
                        }

                        .modal-footer {
                            .disabled {
                                cursor: not-allowed;
                            }
                        }
                    }

                    .modal {
                        z-index: 9990;
                    }

                    .modal-backdrop {
                        z-index: 9989;
                    }
                }

                .ModalChangePassword {
                    font-family: $mainFont;

                    .modal-content {
                        max-width: 400px;

                        P {
                            margin: 0;
                            margin-bottom: 5px;
                            font-weight: 500;
                            color: #515151 !important;

                            &.error {
                                color: red !important;
                            }
                        }

                        .ant-input-password {
                            margin-bottom: 15px;
                        }

                        input {
                            color: #131313;
                        }

                        button {
                            &.disabled {
                                cursor: not-allowed;
                            }
                        }
                    }

                    .modal {
                        z-index: 9992;

                        .modal-body {
                            padding-bottom: 0;
                        }
                    }

                    .modal-backdrop {
                        z-index: 9991;
                    }
                }
            }
        }

        //in header the global add button css
        // .btn {
        //     display: inline-flex;
        //     align-items: center;
        //     justify-content: center;
        //     white-space: nowrap;
        //     border-radius: 9999px;
        //     font-weight: 600;
        //     font-size: 18px;
        //     line-height: 24px;
        //     box-shadow: none !important;

        //     &:focus-visible {
        //         outline: none !important;
        //     }

        //     &:disabled {
        //         filter: opacity(90%);
        //         cursor: not-allowed !important;

        //         &:hover {
        //             filter: opacity(90%);
        //         }
        //     }
        // }

        // .btn-primary {
        //     background: $primaryBlue;
        //     border-color: $primaryBlue;

        //     &:hover {
        //         filter: opacity(90%);
        //     }
        // }

        // .btn-outline {
        //     border: $primaryBlue;
        //     background-color: $background;
        // }

        // .btn-info {
        //     background: $primaryColor;

        //     &:active {
        //         background: $primaryColor !important;
        //     }

        //     &:focus {
        //         background: $primaryColor !important;
        //     }

        //     &:hover {
        //         background: $primaryColor;
        //     }
        // }

        // .btn {
        //     display: inline-flex;
        //     align-items: center;
        //     justify-content: center;
        //     white-space: nowrap;
        //     border-radius: 9999px;
        //     font-weight: 600;
        //     font-size: 18px;
        //     line-height: 24px;
        //     box-shadow: none !important;

        //     &:focus-visible {
        //         outline: none !important;
        //     }

        //     &:disabled {
        //         opacity: 50% !important;
        //         cursor: not-allowed !important;
        //     }
        // }

        // .btn-primary {
        //     background: $primaryBlue;
        //     border-color: $primaryBlue;

        //     &:hover {
        //         opacity: 90%;
        //     }
        // }

        // .btn-outline {
        //     border: $primaryBlue solid 1px;
        //     background-color: $background;
        // }

        // .btn-info {
        //     border-color: $primaryColor;
        //     color: #fff;

        //     &:active {
        //         background: $primaryColor !important;
        //     }

        //     &:focus {
        //         box-shadow: none;
        //         outline: none;
        //     }

        //     &:hover {
        //         background: $primaryColor;
        //     }
        // }

        // .btn-outline-primary {
        //     border-color: $primaryColor;
        //     color: $primaryColor;

        //     &:active {
        //         background: $primaryColor !important;
        //     }

        //     &:focus {
        //         box-shadow: none;
        //         outline: none;
        //     }

        //     &:hover {
        //         background: $primaryColor;
        //     }
        // }

        //css for sidebar in antD
        .siderBar {
            font-family: $mainFont;

            .ant-menu-root {
                padding-top: 10px;
            }

            .ant-menu {
                padding-left: 15px;
                color: $unSelectedMenueColor;

                .createButton {
                    font-family: $mainFont;
                    border: 1px solid;
                    border-radius: 32px;
                    width: 192px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: center;
                    color: $primaryColor;
                    margin-bottom: 24px;
                    padding: 4px 24px 43px 24px;
                }

                .sideBarMenu {
                    padding: 8px 0;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;

                    svg {
                        path {
                            fill: $unSelectedMenueColor;
                        }
                    }

                    &.ant-menu-item-selected {
                        background: #fff;
                        border-right: 2px solid $primaryColor;
                        color: $primaryColor;

                        svg {
                            path {
                                fill: $primaryColor;
                            }
                        }
                    }

                    .sideBarMenueIcons {
                        margin-right: 15px;
                    }
                }

                .ant-menu-submenu-inline {
                    .sideBarMenueIcons {
                        margin-right: 15px;
                    }
                }
            }
        }

        //css for sidebar in antD
        //done when display is small screen and hamburger is open to show the drawer on top this is done
        .c-sidebar-backdrop.c-show {
            z-index: 100 !important;
        }

        //done when display is small screen and hamburger is open to show the drawer on top this is done

        .coreUiSideBar {

            // top: 57px;
            .logo {
                justify-content: center;
                padding-right: 15px;
                // padding-left: 15px;
            }

            .c-show,
            .c-sidebar-nav-dropdown {
                // background-color: #131A2C !important;

            }

            .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle::after {
                display: block;
            }

            .c-sidebar-nav-dropdown-toggle {
                display: flex;
                gap: 18px;
                // background-color: #131A2C !important;

                // color: white !important;

                font-family: Open Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;

                ::after {
                    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 0, 0, 1)' d=' M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
                    background: none !important;
                    height: auto !important;

                    :hover {
                        content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 0, 0, 1)' d=' M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
                    }
                }

                svg {
                    // color: white !important;
                }
            }

            .c-sidebar-nav-dropdown-toggle::after {
                content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 1)' d=' M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
            }

            .c-sidebar-nav-dropdown-items {


                .c-sidebar-nav-item {
                    padding-left: 3.5rem !important;

                    :hover {
                        background-color: #2D4664 !important;
                    }
                }
            }


            .c-sidebar-nav-title {

                color: $secondaryText;
                font-size: 12px;
                font-family: $openSans;
                font-weight: 600;
                line-height: 16px;
                word-wrap: break-word;
                user-select: none;
                text-transform: none;
            }

            .c-sidebar-nav-item {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 256px;
                padding: 8px 16px;

                // color: white;
                // font-size: 15px;
                font-family: $openSans;
                font-weight: 600;
                // line-height: 24px;
                word-wrap: break-word;

                .c-sidebar-nav-item__icon {
                    height: 24px;
                    width: 24px;
                }
            }

            .c-sidebar-nav-item__small {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                gap: 12px;
            }

            .c-sidebar-nav-divider {
                background-color: $white;
                height: 1px;
                margin-left: 1.5rem;
                margin-right: 1.5rem;
                margin-top: 2.5rem;
                margin-bottom: 1rem;
            }

            .c-sidebar-nav,
            .c-sidebar-minimizer {
                background: $sidebarBlue;
            }

            .c-sidebar-nav {
                .c-sidebar-nav-dropdown {
                    UL.c-sidebar-nav-dropdown-items {
                        li.c-sidebar-nav-item {
                            padding-left: 12px;

                            a.c-sidebar-nav-link {
                                &:hover {
                                    background: none;
                                }
                            }
                        }
                    }
                }

                .c-sidebar-nav-dropdown,
                .c-sidebar-nav-item {
                    a {
                        color: $white;

                        svg {
                            color: $white;
                        }

                        &.c-active {
                            background: $sidebarHighlight;
                            color: #fff;

                            svg {
                                color: #fff;
                            }
                        }
                    }

                    &:hover {
                        background: $sidebarHighlight;
                        cursor: pointer;

                        a {
                            background: transparent;
                            color: #fff;
                        }

                        svg {
                            color: #fff;
                        }
                    }

                    &.c-show {
                        background: #fff !important;

                        a {
                            &.c-active {
                                background: $sidebarHighlight;
                                color: #fff;

                                svg {
                                    color: #fff;
                                }
                            }
                        }

                        .c-sidebar-nav-link {
                            color: #000;

                            svg {
                                color: #000;
                            }

                            &:hover {
                                background: $primaryColor;
                                color: #fff;

                                svg {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        //css for modal - TemplatePreviewModal And sendEMailConfirm Starts
        .templatePreviewModal {
            font-family: $mainFont;
            width: auto !important;
            max-width: 800px !important;
            z-index: 1000;
            top: 10px !important;

            .ant-modal-header {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                border: none;
            }

            .ant-modal-footer {
                border: none;

                .ant-btn {
                    font-family: $mainFont;
                    height: 40px;
                    border-radius: 8px;
                    padding: 10px 16px;
                    border: none;
                    border: 1px solid #d1d1d1;

                    &:hover {
                        color: #131313;
                    }
                }

                .ant-btn.ant-btn-primary {
                    font-family: $mainFont;
                    height: 40px;
                    background: $primaryColor;
                    border-radius: 8px;
                    padding: 10px 16px;
                    border: none;
                    color: #fff;
                }
            }
        }

        .sendEmailModal {
            font-family: $mainFont;
            width: auto !important;
            max-width: 500px !important;
            z-index: 1000;
            top: 10px !important;

            .modal-body {
                .recipientsName {
                    .selectedRecipients {
                        display: inline-block;
                        background: #f6f6f6;
                        padding: 11px 10px 0px 10px;
                        border-radius: 20px;
                        margin: 2px 5px;
                    }
                }
            }

            .modal-footer {
                button {
                    &.disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }

        //before sending out messae in tmepalte
        .confirmModal {
            .ant-modal-confirm-btns {
                .ant-btn {
                    border-radius: 8px;
                    color: #131313;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    height: 40px;

                    span {
                        padding: 5px;
                    }
                }

                .ant-btn-primary {
                    height: 40px;
                    font-family: $mainFont;
                    background: $primaryColor;
                    color: #fff;
                    border-radius: 8px;

                    span {
                        padding: 5px;
                    }
                }
            }
        }

        //before sending out messae in tmepalte

        .tempaltesListContainer {
            margin: 0 24px;
            padding: 0 15px;

            .template-heading {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                color: #000;
                padding: 10px 15;
                margin-top: 24px;
                margin-bottom: 24px;
            }

            .templateTypeRow {
                padding: 0 15px;
                border-bottom: 1px solid $templateBordersColor;

                &:hover {
                    background: #f6f6f6;
                }

                .Templatetype {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #131313;
                    padding: 22px 0;
                }

                .eyeIconDiv {
                    font-size: 20px;
                    display: inline-block;
                    cursor: pointer;
                    padding: 3px 11px 9px 12px;
                    background: #fff;
                    border-radius: 50%;
                    margin: 10px 15px 0 0;
                    border: 1px solid #d1d1d1;
                }

                .BUttonDiv {
                    display: inline-block;

                    button {
                        color: #131313;
                        background: #fff;
                        cursor: pointer;
                        width: 153px;
                        padding: 10px 15px;
                        border-radius: 8px;
                        border-color: #131313;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }

        .c-body {
            background-color: $background;

            .viewAll {
                // text-decoration: underline;
                color: #2b4f7c;
                font-weight: 600;

                &:hover {
                    cursor: pointer;
                }
            }

            // .csvButton {
            //   flex-direction: row;
            //   justify-content: center;
            //   align-items: center;
            //   padding-top: 7px;
            //   position: static;
            //   width: 152px;
            //   height: 40px;
            //   right: 130px;
            //   top: 0px;
            //   font-weight: bold;
            //   /* body */
            //   background: #ffffff;
            //   /* neutral / 900 */
            //   border: 1px solid #131313;
            //   box-sizing: border-box;
            //   border-radius: 8px;
            //   /* Inside Auto Layout */
            //   flex: none;
            //   order: 2;
            //   flex-grow: 0;
            //   margin: 0px 12px;
            // }

            .offerDetail,
            .modalAmenityDetail,
            .EditAmenitiesForm {
                .modal-dialog {
                    position: fixed;
                    right: 0;
                    top: 0;
                    width: 450px;
                    height: 100%;
                    margin: 0;

                    .modal-content {
                        height: 100%;

                        .modal-body {
                            height: 100%;
                            padding: 0 15px;
                            overflow-y: scroll;
                            overflow-x: hidden;

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }
                        }
                    }
                }
            }

            .card {
                .card-body {
                    .position-relative.table-responsive {
                        overflow: visible;
                        min-height: 400px;
                    }
                }
            }

            .unitAmenitiesMainDiv,
            .reportsMainDiv {

                .info-text-container {
                    width: 100%;
                    text-align: center;
                    color: #3c4b64;
                }

                .nav-link {
                    color: $primaryColor;
                }

                .active {
                    color: $unSelectedMenueColor;
                }
            }

            .bookingsMainDiv {


                .Heading {
                    margin-bottom: 30px;

                    .card-header {
                        padding-top: 15px;
                    }
                }

                h2 {
                    font-weight: bold
                }

                .buildingHeader {
                    .card-header {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .tabRow {
                    // border-bottom: 1px solid #e3e3e3;
                    padding: 0 15px;
                    // margin-bottom: 15px;

                    .option {
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                        }

                        &:nth-child(1) {
                            margin-left: 15px;
                        }

                        h4 {
                            color: #768192;
                        }

                        &.selectedTab {
                            // padding-bottom: 10px;
                            // border-bottom: 1px solid $primaryColor;

                            h4 {
                                font-size: 23px;
                                color: $primaryColor;
                            }
                        }
                    }
                }

                .card {
                    box-shadow: none;
                }
            }

            .deliveryCodesMainDiv {
                .Heading {
                    margin-bottom: 30px;

                    .card-header {
                        padding-top: 15px;
                    }
                }

                h2 {
                    font-weight: bold
                }

                .buildingHeader {
                    .card-header {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .tabRow {
                    padding: 0 15px;

                    .option {
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                        }

                        &:nth-child(1) {
                            margin-left: 15px;
                        }

                        h4 {
                            color: #768192;
                        }

                        &.selectedTab {
                            h4 {
                                font-size: 23px;
                                color: $primaryColor;
                            }
                        }
                    }
                }

                .card {
                    box-shadow: none;
                }
            }

            .modalAmenityDetail {
                .modal-content {
                    max-width: 450px;

                    .editButtinDiv {
                        margin: 0;
                        margin-bottom: 25px;
                        border-bottom: 1px solid #e3e3e3;
                        color: black;

                        .editIconDiv {
                            padding: 15px 0;
                            cursor: pointer;
                        }
                    }

                    .modal-body {
                        padding: 0 15px;

                        .DetailRow {
                            padding-bottom: 15px;
                            margin-bottom: 15px;
                            border-bottom: 1px solid #e3e3e3;

                            .IconCol {
                                display: flex;
                                align-items: center;
                            }

                            .key {
                                font-size: 14px;
                            }

                            .value {
                                color: #131313;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .modalTemplatePrev {
                overflow-x: hidden;
                overflow-y: scroll;

                .modal-body {
                    height: 500px;

                    color: red;

                    @media (min-height: 800px) {
                        height: 700px;
                    }

                    overflow-y: scroll;
                    overflow-x: hidden;

                    &::-webkit-scrollbar-track {
                        display: none;
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: none;
                    }

                    &:hover {
                        &::-webkit-scrollbar-track {
                            display: none;
                            // border: none;
                            // background-color: #f5f5f5;
                            // border-radius: 10px;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: $primaryColor;
                        }
                    }
                }
            }

            .editOfferfrm {
                .modal-dialog {
                    position: fixed;
                    //z-index: 1000;
                    right: 0;
                    top: 0;
                    width: 450px;
                    height: 100%;
                    margin: 0;

                    .modal-content {
                        height: 100%;
                        max-width: 450px;

                        .modal-body {
                            overflow-y: scroll;
                            overflow-x: hidden;

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            .logoImg {
                                width: 100%;
                                height: 120px;
                                object-fit: fill;
                            }
                        }
                    }
                }

                // .modal-content {
                //     max-width: 450px;
                // }
            }

            .deleteModal {

                .modal-header,
                .modal-footer {
                    border: none;
                }

                .modal-body {
                    svg {
                        color: red;
                        width: 20px !important;
                        height: 20px !important;
                        margin-bottom: 15px;
                    }
                }

                .modal-footer {
                    .cancelButton {
                        border-radius: 8px;
                        background: none;
                        color: #131313;
                        padding: 10px 15px;
                        font-weight: 700;
                    }

                    .deleteButton {
                        border-radius: 8px;
                        background: red;
                        color: #fff;
                        padding: 10px 15px;
                        font-weight: 700;
                    }
                }
            }

            background: #fff;

            .tableEditDropdown {
                height: 100%;
                width: 100%;

                .dropdown-toggle {
                    box-shadow: none !important;
                    font-weight: 900;
                    font-size: 20px;
                    height: 100%;
                    width: 100%;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    .dropdown-item {
                        color: black;

                        &:active {
                            background: none !important;
                            color: black;
                        }

                        svg {

                            &.edit,
                            &.lock {
                                width: 20px;
                                stroke-width: 20px;
                                stroke: black;
                            }
                        }

                        &.deleteOption {
                            color: red;

                            svg {
                                &.delete {
                                    width: 20px;
                                    stroke-width: 10px;
                                    stroke: red;
                                }
                            }
                        }
                    }
                }
            }

            .unitPinModal {
                .modal-content {
                    width: 200px;
                    margin: 0 auto;

                    .modal-body {
                        text-align: center;
                    }
                }
            }

            .notificationsWrapper {

                // td {
                //   vertical-align: middle;
                //   .imgDiv {
                //     width: 50px;
                //     image {
                //       width: 100%;
                //     }
                //   }
                // }
                .card-header {
                    margin-bottom: 15px;
                    position: sticky;
                    z-index: 1;
                    top: 50px;

                    .searchMainDiv {
                        input {
                            &:focus {
                                border-color: #e3e3e3;
                                box-shadow: none;
                            }
                        }
                    }
                }

                .notifContainer {
                    max-width: 800px;
                    width: 100%;
                    margin: 0 auto;

                    .markAllRead {
                        text-align: right;
                        cursor: pointer;
                        color: $primaryColor;
                    }

                    .row {
                        cursor: pointer;
                        margin-bottom: 1px;
                        padding-top: 10px;

                        &:hover {
                            background: #e3e3e3;
                        }

                        &.unread {
                            background: #e3e3e3;
                        }

                        .imgCol {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .imgDiv {
                                max-width: 50px;
                                width: 100%;

                                img {
                                    width: 100%;
                                    vertical-align: middle;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .notifType {
                            color: $primaryColor;
                        }
                    }

                    .loadPrev {
                        margin-top: 15px;
                        color: $primaryColor;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }

            .pagination {
                .page-item {
                    a {
                        color: $primaryColor !important;
                    }

                    &.active {
                        a {
                            color: #fff !important;
                            background: $primaryColor !important;

                            &:focus {
                                box-shadow: none !important;
                            }
                        }
                    }
                }
            }

            .dashboardMainDiv {
                .daboardTitleDiv {
                    padding: 15px 0 0 15px;
                    border-bottom: 1px solid #e3e3e3;
                }
            }

            //conversation page starts here
            .ConversationTemplateWrapper {

                height: 100%;

                .noEmailSentDiv {
                    font-family: $mainFont;
                    max-width: 380px;
                    margin: 0 auto;
                    margin-top: 40px;
                    text-align: center;

                    .imgDiv {
                        width: 176px;
                        margin: 0 auto;
                        margin-bottom: 32px;

                        svg {
                            width: 100%;
                        }
                    }

                    h6 {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;
                    }

                    button {
                        margin-top: 32px;
                        margin-bottom: 30px;
                        background: $primaryColor;
                        border-radius: 8px;
                        align-items: center;
                        padding: 10px 16px;
                        color: #fff;
                        border: none;
                    }
                }

                .navBarHeader {
                    background: #fff;
                    padding: 0 24px;
                    border-bottom: 1px solid lightgray;

                    .logo {
                        width: 100px;
                        display: inline-block;
                    }

                    ul {
                        &.ant-menu {
                            font-family: $mainFont;
                            float: right;

                            li {
                                color: $headerUnSelectedColor;
                                font-weight: 500;
                                font-size: 14px;

                                //for hiding css on hover
                                &.ant-menu-item-active {
                                    &:hover {
                                        color: $headerUnSelectedColor;
                                        border-color: transparent;
                                    }
                                }

                                //showing css on selected
                                &.ant-menu-item-selected {
                                    color: $primaryColor;
                                    border-bottom: 2px solid $primaryColor;

                                    //if nav is selected and user hover it dont change anything
                                    &.ant-menu-item-active {
                                        &:hover {
                                            color: $primaryColor;
                                            border-bottom: 2px solid $primaryColor;
                                        }
                                    }
                                }

                                //removing margin for icon in navBar
                                &.ant-menu-item,
                                &.navBarIcon {
                                    margin-right: 0px;
                                }

                                //removing bordeBottom for icon in navBar
                                &.navBarIcon {
                                    span {
                                        &.anticon {
                                            font-size: 20px;

                                            &.anticon-bell {
                                                margin-right: 26px;
                                            }
                                        }
                                    }

                                    color: $headerUnSelectedColor;
                                    border-bottom: none;

                                    &.ant-menu-submenu-selected,
                                    &.ant-menu-submenu-active {
                                        color: $headerUnSelectedColor;
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }

                //css for navBar ends, component = "theHeader"
                //
                //
                //
                //css for sidebar start, component = "theSider"
                .siderBar {
                    font-family: $mainFont;

                    .ant-menu {
                        padding-top: 24px;
                        padding-left: 15px;
                        color: $unSelectedMenueColor;

                        .createButton {
                            font-family: $mainFont;
                            border: 1px solid;
                            border-radius: 32px;
                            width: 130px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            text-align: center;
                            color: $primaryColor;
                            margin-bottom: 24px;
                            padding: 4px 24px 43px 24px;
                        }

                        .ant-menu-sub {
                            padding: 0;

                            .sideBarMenu {
                                padding: 8px 0;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;

                                svg {
                                    path {
                                        fill: $unSelectedMenueColor;
                                    }
                                }

                                &.ant-menu-item-selected {
                                    background: #fff;
                                    border-right: 2px solid $primaryColor;
                                    color: $primaryColor;

                                    svg {
                                        path {
                                            fill: $primaryColor;
                                        }
                                    }
                                }

                                .sideBarMenueIcons {
                                    margin-right: 15px;
                                }

                                ant-menu-submenu-title {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

                //css for sidebar ends, component = "theSider"
                //css for TOP BAr which is visible in MEssage/ Conversation/ tempalte

                .tableMainContainer {
                    font-family: $mainFont;

                    .topRow {
                        text-align: right;
                        margin: 0 0 24px 0;

                        .searchTextField {
                            width: 320px;
                            margin-right: 12px;

                            span {
                                .ant-input-group {
                                    border: 1px solid #d1d1d1;
                                    border-radius: 8px;

                                    .ant-input-affix-wrapper {
                                        padding: 3px;
                                        border: none;

                                        .ant-input-prefix,
                                        .ant-input-suffix {
                                            margin: 0 15px;

                                            svg {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }

                            input {
                                height: 40px;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                            }

                            button {
                                &.ant-input-search-button {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                // this is the bar whichi is visible in conversation screens
                .draft-top-bar {
                    .topBar {
                        font-family: $mainFont;
                        border-bottom: 2px solid $templateBordersColor;
                        background: #fff;

                        h3,
                        p {
                            display: inline-block;
                            margin: 0;
                        }

                        h3 {
                            margin: 28px 8px 28px 24px;
                        }
                    }
                }

                .topBar {
                    background: #eeeeee;
                    margin-bottom: 64px;

                    .bannerLeftSectionDiv {
                        display: flex;
                        align-items: baseline;

                        .closeIcon {
                            font-size: 20px;
                            margin: 25px 7px 25px 21px;
                            color: #131313;
                        }

                        p {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 28px;
                            color: #131313;
                            margin: 0;
                        }
                    }

                    .bannerRightSection {
                        text-align: right;

                        Button {

                            &.backBtn,
                            &.SaveBtn {
                                font-family: $mainFont;
                                color: #fff;
                                width: 64px;
                                height: 40px;
                                border-radius: 8px;
                                color: #131313;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                margin: 15px 12px 0 0;
                                border: 1px solid #131313;
                            }

                            &.backBtn {
                                margin-left: 15px;
                            }

                            &.nextBtn {
                                font-family: $mainFont;
                                color: #fff;
                                width: 64px;
                                height: 40px;
                                background: $primaryColor;
                                border-radius: 8px;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                margin: 15px 40px 0 0;
                            }

                            &.draft-btn {
                                font-family: $mainFont;
                                color: #131313;
                                width: 64px;
                                height: 40px;
                                background: $secondaryColor;
                                border-radius: 8px;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                margin: 15px 15px 0 0;
                            }

                            &.backBtn,
                            &.SaveBtn,
                            &.draft-btn,
                            &.nextBtn {
                                &:hover {
                                    border: 1px solid #131313;
                                }
                            }
                        }
                    }
                }

                // this is the bar whichi is visible in conversation screens
                //css for EmailTempletesList start
                .tempaltesListWrapper {
                    font-family: $mainFont;

                    .headingRow {
                        border-bottom: 1px solid $templateBordersColor;
                        box-shadow: inset 0px -1px 0px #e3e3e3;
                        margin-bottom: 24px;
                        font-family: $mainFont;

                        h2,
                        p {
                            display: inline-block;
                            margin: 0;
                        }

                        h2 {
                            margin: 28px 15px 28px 24px;
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 32px;
                        }

                        p {
                            font-weight: normal;
                            font-size: 14px;
                            padding-top: 14px;
                        }
                    }
                }

                .template-list-row {
                    gap: 1em;
                    row-gap: 1em !important;
                }

                //css for EmailTempletesList EnDS
                //css for msgReview - ReviewMessage Starts
                .ReviewMessaegContainer {
                    .bodyContainer {
                        max-width: 900px;
                        margin: 0 auto;
                        padding: 0 15px;

                        .headingRow {
                            margin-bottom: 50px;

                            div {
                                &:nth-child(2) {
                                    text-align: right;
                                }

                                h2 {
                                    font-family: $mainFont;
                                    font-size: 28px;
                                    line-height: 36px;
                                    color: #131313;
                                    font-weight: 700;
                                    margin: 0;
                                }

                                p {
                                    margin: 0;
                                    font-weight: normal;
                                    font-size: 14px;
                                    padding-top: 14px;
                                }
                            }
                        }

                        .titleRow {
                            border-bottom: 1px solid $templateBordersColor;

                            p {
                                margin: 10px 0;

                                &.sujectText {
                                    font-weight: 400;
                                }
                            }
                        }

                        .tempBody {
                            margin-top: 40px;

                            .templateBodyText {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }

                //css for msgReview - ReviewMessage ENDS
                //css for selecting reipient in create tempalete - SelectRecipients Starts
                .selectRecipientContainer {
                    .bodyContainer {
                        max-width: 760px;
                        margin: 0 auto;
                        padding: 0 15px;

                        .headingRow {
                            margin-bottom: 50px;

                            div {
                                &:nth-child(2) {
                                    text-align: right;
                                }

                                h2 {
                                    font-family: inter;
                                    font-size: 28px;
                                    line-height: 36px;
                                    color: #131313;
                                    font-weight: 700;
                                    margin: 0;
                                }

                                p {
                                    margin: 0;
                                    font-weight: normal;
                                    font-size: 14px;
                                    padding-top: 14px;
                                }
                            }
                        }

                        .searchTextField {
                            margin-bottom: 27px;

                            span {
                                &.ant-input-group {
                                    border: 1px solid #d1d1d1;
                                    border-radius: 4px;

                                    .ant-input-search-button {
                                        display: none;
                                    }

                                    .ant-input-affix-wrapper {
                                        padding: 3px;
                                        border: none;

                                        input {
                                            font-family: $mainFont;
                                            height: 40px;
                                            font-size: 18px;
                                            line-height: 20px;
                                            font-weight: 400;
                                        }

                                        .ant-input-prefix,
                                        .ant-input-suffix {
                                            margin: 0 17px;

                                            svg {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .recipientSelectedRow {
                            margin-bottom: 20px;

                            div {
                                &:nth-child(2) {
                                    text-align: right;
                                }

                                h2 {
                                    font-family: inter;
                                    font-size: 28px;
                                    line-height: 36px;
                                    color: #131313;
                                    font-weight: 700;
                                    margin: 0 15px 0 0;
                                    display: inline-block;
                                }

                                p {
                                    font-family: $mainFont;
                                    margin: 0;
                                    font-weight: 400;
                                    font-size: 20px;
                                    padding-top: 14px;
                                    display: inline-block;

                                    &.clearFilter {
                                        font-family: $mainFont;
                                        margin: 0;
                                        font-weight: 600;
                                        font-size: 20px;
                                        padding-top: 14px;
                                        display: inline-block;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .no-arrow .ant-tree-switcher {
                            display: none;
                        }

                        .recipientSelectedAll {
                            display: flex;
                            flex-direction: row;
                            margin-bottom: 10px;

                            p {
                                font-family: $mainFont;
                                color: #131313;
                                margin: 0;
                                font-weight: 500;
                                font-size: 16px;
                                padding-top: 0;
                                display: inline-block;
                                margin-left: 5px;
                            }
                        }

                        .recipientSelectedAllFloors {
                            display: flex;
                            flex-direction: row;
                            //margin-bottom: 5px;

                            p {
                                font-family: $mainFont;
                                color: #131313;
                                margin: 0;
                                font-weight: 500;
                                font-size: 13px;
                                padding-top: 0;
                                display: inline-block;
                                margin-left: 5px;
                            }
                        }

                        .recipientSelectedRowMid {
                            margin-bottom: 5px;

                            div {
                                &:nth-child(2) {
                                    text-align: right;
                                }

                                h3 {
                                    font-family: inter;
                                    font-size: 24px;
                                    line-height: 36px;
                                    color: #131313;
                                    font-weight: 700;
                                    margin: 0 15px 0 0;
                                    display: inline-block;
                                }
                            }
                        }

                        .selectedRecipientsRow {
                            padding-bottom: 10px;
                            margin-bottom: 26px;
                            border-bottom: 1px solid $templateBordersColor;

                            .selectedRecipients {
                                display: inline-block;
                                background: #f6f6f6;
                                padding: 11px 10px 0px 10px;
                                border-radius: 20px;
                                margin: 3px 5px;
                            }
                        }

                        .checkBoxTree {
                            margin-bottom: 20px;
                        }

                        .buildingsCheckBoxTree {
                            margin-bottom: 0;
                        }
                    }
                }

                //css for selecting reipient in create tempalete - SelectRecipients ENDS
                //css for confirming details and send in create tempalete - confirmDetailsAndSend Starts
                .confirmDetailsContainer {
                    .bodyContainer {
                        font-family: $mainFont;
                        max-width: 760px;
                        margin: 0 auto;
                        padding: 0 15px;

                        .headingRow {
                            margin-bottom: 50px;

                            div {
                                &:nth-child(2) {
                                    text-align: right;
                                }

                                h2 {
                                    font-family: $mainFont;
                                    font-size: 28px;
                                    line-height: 36px;
                                    color: #131313;
                                    font-weight: 700;
                                    margin: 0;
                                }

                                p {
                                    margin: 0;
                                    font-weight: normal;
                                    font-size: 14px;
                                    padding-top: 14px;
                                }
                            }
                        }

                        .recipientAndMessageDiv {
                            h3 {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                margin: 0;
                            }

                            .toRecipientDiv {
                                .toRecipientRow {
                                    padding: 15px 0;
                                    margin-bottom: 16px;
                                    border-bottom: 1px solid #e3e3e3;

                                    .selectedRecipients {
                                        display: inline-block;
                                        background: #f6f6f6;
                                        padding: 11px 10px 0px 10px;
                                        border-radius: 20px;
                                        margin: 2px 5px;
                                    }

                                    .editCol {
                                        text-align: right;
                                        cursor: pointer;

                                        div {
                                            width: 40px;
                                            margin: 0 auto;
                                            padding: 11px;
                                            border-radius: 50%;
                                            border: 1px solid #d1d1d1;
                                            line-height: 15px;
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }

                            .toMessageDiv {
                                .toMessageRow {
                                    padding: 15px 0;
                                    margin-bottom: 16px;
                                    border-bottom: 1px solid #e3e3e3;

                                    .prefixIconDiv,
                                    .messageTextDiv {
                                        display: inline-block;
                                    }

                                    .prefixIconDiv {
                                        line-height: 6px;
                                        padding: 13px;
                                        background: #e5e9ee;
                                        border-radius: 70%;
                                        margin-right: 16px;
                                    }

                                    .messageTextDiv {
                                        p {
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #131313;
                                            margin: 0;
                                        }
                                    }

                                    .editCol {
                                        text-align: right;
                                        cursor: pointer;

                                        div {
                                            width: 40px;
                                            margin: 0 auto;
                                            padding: 11px;
                                            border-radius: 50%;
                                            border: 1px solid #d1d1d1;
                                            line-height: 15px;
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //css for confirming details and send in create tempalete - confirmDetailsAndSend Ends
            }

            .ConversationSentWrapper {
                font-family: $mainFont;

                .topBar {
                    font-family: $mainFont;
                    border-bottom: 2px solid $templateBordersColor;

                    h3,
                    p {
                        display: inline-block;
                        margin: 0;
                    }

                    h3 {
                        margin: 28px 8px 28px 24px;
                    }

                    // .exportBtnCol {
                    //   text-align: right;
                    //   .csvButton {
                    //     font-weight: 600;
                    //     font-family: $mainFont;
                    //     color: #131313;
                    //     font-size: 14px;
                    //     line-height: 20px;
                    //     box-shadow: none !important;
                    //     border: 1px solid #131313 !important;
                    //     margin: 25px;
                    //   }
                    // }
                }

                .noEmailSentDiv {
                    font-family: $mainFont;
                    max-width: 380px;
                    margin: 0 auto;
                    margin-top: 40px;
                    text-align: center;

                    .imgDiv {
                        width: 176px;
                        margin: 0 auto;
                        margin-bottom: 32px;

                        svg {
                            width: 100%;
                        }
                    }

                    h6 {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;
                    }

                    button {
                        margin-top: 32px;
                        margin-bottom: 30px;
                        background: $primaryColor;
                        border-radius: 8px;
                        align-items: center;
                        padding: 10px 16px;
                        color: #fff;
                        border: none;
                    }
                }

                .modalShowTemplates {
                    font-family: $mainFont;

                    .modal-body {
                        .leftCol {
                            overflow-y: scroll;
                            height: 500px;

                            @media (min-height: 800px) {
                                height: 650px;
                            }

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }

                            .searchTextField {
                                margin-bottom: 24px;

                                .ant-input-affix-wrapper {
                                    border-radius: 8px;

                                    input {
                                        font-family: $mainFont;
                                        font-size: 14px;
                                        line-height: 20px;
                                        padding: 5px;
                                    }
                                }

                                .ant-input-group-addon {
                                    display: none !important;
                                }
                            }

                            h6 {
                                font-weight: 500;
                                font-size: 14px;
                                padding-bottom: 5px;
                                line-height: 20px;
                                border-bottom: 1px solid #e3e3e3;
                            }

                            .templates {
                                margin-bottom: 20px;

                                button {
                                    svg {
                                        margin-right: 5px;
                                    }

                                    text-align: left;
                                    border: none !important;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #131313;
                                    background: transparent;
                                    display: block;
                                    box-shadow: none !important;
                                    width: 100%;
                                    margin-bottom: 3px;
                                    padding: 10px;

                                    &.selected {
                                        background: #f6f6f6;
                                    }
                                }
                            }
                        }

                        .rightCol {
                            overflow-y: scroll;
                            height: 500px;

                            @media (min-height: 800px) {
                                height: 650px;
                            }

                            &::-webkit-scrollbar-track {
                                display: none;
                            }

                            &::-webkit-scrollbar {
                                width: 10px;
                                background-color: none;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                background-color: none;
                            }

                            &:hover {
                                &::-webkit-scrollbar-track {
                                    display: none;
                                    // border: none;
                                    // background-color: #f5f5f5;
                                    // border-radius: 10px;
                                }

                                &::-webkit-scrollbar {
                                    width: 10px;
                                    background-color: none;
                                }

                                &::-webkit-scrollbar-thumb {
                                    border-radius: 10px;
                                    background-color: $primaryColor;
                                }
                            }
                        }
                    }

                    .modal-footer {
                        .disabled {
                            cursor: not-allowed;
                            background: rgb(180, 174, 174);
                        }
                    }
                }

                .sentMsgTable {
                    font-family: $mainFont;

                    table {
                        tr {
                            td {
                                &:nth-child(1) {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #131313;
                                }
                            }
                        }
                    }
                }
            }

            //conversation page Ends here

            .bookAmenitiesTable,
            .bookParkingTable {
                font-family: $mainFont;

                .topBar {
                    margin-top: 1px solid #e3e3e3;

                    .leftCol {
                        padding: 25px 0 15px 20px;

                        h3,
                        p {
                            margin: 0;
                            display: inline-block;
                        }

                        h3 {
                            margin-right: 15px;
                        }
                    }

                    .rightCol {
                        padding: 25px 50px 15px 0;
                        text-align: right;

                        .search-bar {
                            position: absolute;
                            border: none;
                            height: 40px;
                            width: auto;
                            left: 0%;
                            right: 0%;
                            top: 0px;
                            padding-bottom: 4px;
                            padding-left: 20px;
                            margin-left: auto;
                            /* neutral / 100 */

                            background: #eeeeee;
                            border-radius: 32px;
                        }

                        // .csvButton {
                        //   margin-right: 15px;
                        // }

                        .bookAmenityBtn {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 16px;
                            position: static;
                            width: 140px;
                            height: 40px;
                            right: 0px;
                            top: 0px;
                            /* primary/500 */

                            background: #2b4f7c;
                            border-radius: 8px;
                            /* Inside Auto Layout */

                            flex: none;
                            order: 3;
                            flex-grow: 0;
                            margin: 0px 12px;
                        }
                    }
                }

                .card-header {
                    padding: 0;
                }

                .bookedCanceldDiv {
                    margin-top: 10px;
                    margin-bottom: 20px;

                    ul {
                        border-radius: 8px;
                        border: 1px solid #e3e3e3;
                        margin-right: 25px;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        width: 150px;
                        padding: 5px;
                        display: in;
                        float: left;

                        &:last-child {
                            float: none;
                        }

                        li {
                            display: inline-block;

                            .iconDiv {
                                background: #f6f6f6;
                                border-radius: 8px;
                                padding: 10px;
                                margin-right: 10px;

                                &.tickIcon {
                                    color: green;
                                }

                                &.crossIcon {
                                    color: red;
                                }
                            }

                            p {
                                margin: 0;

                                &:nth-child(1) {
                                    font-size: 16px;
                                    color: #131313;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }

        .addUsersModal {
            .addUsersContainer {
                display: flex;
                justify-content: center;
            }

            .addUsers-select {
                width: 30%;
                margin: 0 15px 20px 15px;
            }

            .addUsers-table-name {
                min-width: 150px;
                max-width: 170px;
                font-size: 14;
                font-weight: 700;
            }

            .addUsers-table-email {
                min-width: 200px;
                max-width: 220px;
                word-wrap: break-word;
            }

            .addUsers-table-phone {
                width: 140px;
            }

            .addUsers-table-unit {
                width: 50px;
            }

            .addUsers-table-type {
                width: 100px;
                text-transform: capitalize;
            }

            .addUsers-table-status {
                width: 80px;
            }

            .addUsers-table-date {
                width: 105px;
            }
        }
    }

    .template-page {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: auto;
        padding: 20px 8px 10px 8px;
        border-radius: 10px;
        border-width: 1px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;

        & .c-icon {
            height: 100px !important;
            width: 150px !important;
            margin-bottom: 10px;
        }

        .template-name {
            overflow-wrap: normal;
            text-align: center;
        }

        &:hover .eye-icon {
            display: inline-block !important;
        }

        .eye-icon {
            font-size: 20px;
            display: none;
            cursor: pointer;
            padding: 3px 11px 9px 12px;
            background: #fff;
            border-radius: 50%;
            border: 1px solid #d1d1d1;
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 99;
        }
    }

    .deals-stat-table {
        .deals-icon {
            height: 40px;
            width: 40px;
            object-fit: contain;
            border-radius: 100%;
            margin-right: 10px;
        }

        .company-column {
            white-space: nowrap;
            font-size: '14px';
        }

        & td {
            min-width: 100px;
        }
    }

    .padding-vertial-lg {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .deal-stats {
        color: $primaryColor;
        padding: 0 20px;

        .deal-logo {
            width: 100px;
            height: 100px;
            object-fit: contain;
            border-radius: 100%;
            display: block;
        }

        .name-span {
            flex-grow: 2;
        }

        .company-name-column {
            margin: auto 0;
        }

        .deal-data-row {
            margin: 10px 0;

            & .col {
                display: flex;
                justify-content: center;
            }
        }
    }
}